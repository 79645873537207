import React from 'react';
import SolutionCategory from '../../Components/SolutionCategory';

const Audio = () => {
	return (
		<SolutionCategory id={5} title="Audio Visual and Sound Systems" description="" />
	);
};

export default Audio;
