import React from 'react';
import SolutionCategory from '../../Components/SolutionCategory';

const Network = () => {
	return (
		<SolutionCategory id={2} title="Network Infrastructure Cabling" description="" />
	);
};

export default Network;
